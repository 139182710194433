<template>
    <div class="box">
        <Header type="expert" :logo="logoUrl" :navList="navList" :typeList="[]" :navCurrentId="navCurrentId">
            <div slot="search" style="display: none"></div>
        </Header>

        <div class="content">
            <div class="title">{{ queryData.name }}</div>
            <div class="search">
                <a-input-search
                    placeholder="请输入专科分会名称"
                    enter-button="搜索"
                    size="large"
                    allowClear
                    @pressEnter="onSearch($event.currentTarget.value)"
                    @search="onSearch"
                />
            </div>
            <div class="search-result flex x-left y-center flex-wrap">
                <div class="item flex x-center y-center" v-for="(item,index) in subjectData" :key="index"  @click="handleToNextPage(item)">{{ item.branch_name }}</div>
            </div>
        </div>


        <Footer></Footer>
    </div>
</template>

<script>
import {exportLogo , expertNavList } from "@/config/const";
import Header from '@/components/layout/layout-header';
import Footer from "@/components/layout/layout-footer.vue";
export default {
    name: "branchHome",
    components : {
        Header , Footer
    },
    data(){
        return {
            logoUrl : exportLogo ,
            navList : expertNavList,
            navCurrentId : 5,
            subjectData : [],
            queryData : {},//路由参数
        }
    },
    created() {
        this.queryData = this.$route.query;
        this.getDataList()
    },
    methods : {
        onSearch(value) {
            if(!value){
                this.subjectData = this.allData;
                return;
            }
            this.subjectData = this.subjectData.filter(item => item.branch_name.indexOf(value) > -1);
        },
        getDataList(){
            this.request.post('BranchDetails',{ committee_id : this.queryData.id}).then(res => {
                this.subjectData = res.data.data;
                this.allData = this.subjectData;
            })
        },
        handleToNextPage(data){
            this.$router.push({
                path : '/job-warehouse/surnameList',
                query : {
                    id : data.list[0].branch_id
                }
            })
        }
    }
}
</script>

<style scoped lang="less">
    .content {
        width: 1240px;
        margin: 0 auto;
        min-height: 500px;
        .title{
            color: #333333;
            font-size: 20px;
            margin: 30px auto 20px;
            font-weight: bold;
        }
        .search{
            width: 650px;
            margin: 0 auto;
        }
        .search-result{
            margin-top: 40px;
            .item{
                width: calc((100% - (48px * 5)) / 6);
                height: 66px;
                background: #FAFBFF;
                border-radius: 3px;
                border: 1px solid #F5F5F5;
                margin-right: 48px;
                margin-bottom: 30px;
                cursor: pointer;
                &:nth-child(6n){
                    margin-right: 0;
                }
                &.active{
                    background: #1E51C9;
                }
            }
        }
    }
    /deep/ .ant-btn-primary{
        width: 100px;
        background: #1E51C9;
        border-color: #1E51C9;
    }
</style>